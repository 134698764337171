const API_URL = process.env.WP_API

async function fetchAPI(query, { variables } = {}) {
    const headers = { 'Content-Type': 'application/json' }

    if (process.env.WORDPRESS_AUTH_REFRESH_TOKEN) {
        headers.Authorization = `Bearer ${process.env.WORDPRESS_AUTH_REFRESH_TOKEN}`
    }

    const res = await fetch(API_URL, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            query,
            variables
        })
    })

    const json = await res?.json()

    if (json.errors) {
        console.error(json.errors)
        throw new Error('Failed to fetch API')
    }
    return json?.data
}

export async function getPreviewPost(id, idType = 'DATABASE_ID') {
    const data = await fetchAPI(
        `
            query PreviewPost($id: ID!, $idType: PostIdType!) {
                post(id: $id, idType: $idType) {
                    databaseId
                    slug
                    status
                }
            }
        `,
        {
            variables: { id, idType }
        }
    )
    return data.post
}

export async function getPosts(lang, endCursor, category) {
    const data = await fetchAPI(
        `
            query postsAndPagination($lang: LanguageCodeFilterEnum!, $endCursor: String!, $category: String!) {
                posts(where: {language: $lang, categoryName: $category}, first: 5, after: $endCursor) {
                    pageInfo {
                      hasNextPage
                      endCursor
                    }
                    nodes {
                        id
                        slug
                        title
                        excerpt
                        slugDe: translation(language: DE) {
                            slug
                        }
                        slugEn: translation(language: EN) {
                            slug
                        }
                        featuredImage {
                            node {
                                sourceUrl
                            }
                        }
                        categories(first: 1) {
                            nodes {
                                slug
                                name
                                slugDe: translation(language: DE) {
                                    slug
                                }
                                slugEn: translation(language: EN) {
                                    slug
                                }
                            }
                        }
                    }
                }
            }
        `,
        {
            variables: {
                lang,
                endCursor,
                category
            }
        }
    )
    return data
}

export async function getSinglePost(slug) {
    const data = await fetchAPI(
        `
            query singlePost($id: ID!) {
                post(idType: SLUG, id: $id) {
                    excerpt
                    title
                    content
                    slug
                    featuredImage {
                        node {
                            sourceUrl
                            altText
                        }
                    }
                    seo {
                        canonical
                        title
                        metaDesc
                        metaKeywords
                    }
                    categories(first: 1) {
                        nodes {
                            slug
                        }
                    }
                    translation(language: DE) {
                        excerpt
                        title
                        content
                        slug
                        featuredImage {
                            node {
                                sourceUrl
                                altText
                            }
                        }
                        seo {
                            canonical
                            title
                            metaDesc
                            metaKeywords
                        }
                    }
                }
            }
        `,
        {
            variables: {
                id: slug
            }
        }
    )
    return data
}
export async function getCategories(lang) {
    const data = await fetchAPI(
        `
            query categoryByLanguage($lang: LanguageCodeFilterEnum!) {
                categories(where: {language: $lang, exclude: [7, 15]}) {
                    nodes {
                        id
                        name
                        slug
                        description
                        seo {
                            metaKeywords
                            metaDesc
                            title
                            canonical
                            opengraphImage {
                                sourceUrl
                            }
                        }
                        slugEn: translation(language: EN) {
                            slug
                        }
                        slugDe:translation(language: DE) {
                            slug
                        }
                    }
                }
            }
        `,
        {
            variables: {
                lang
            }
        }
    )
    return data
}

export async function getPage(slug) {
    const data = await fetchAPI(
        `
            query PageBySlug($id: ID!, $idType: PageIdType!) {
                page(id: $id, idType: $idType) {
                    title
                    content
                    slug
                    featuredImage {
                        node {
                          sourceUrl
                          altText
                        }
                    }
                    seo {
                        title
                        canonical
                        metaDesc
                    }
                    acf {
                        subtitle
                        showPartners
                        showTeamMembers
                        showTestimonials
                        slider {
                            title
                            subtitle
                            images {
                                image {
                                    sourceUrl
                                }
                            }
                        }
                        headerText {
                            text
                            button
                        }
                        faqs {
                            question
                            answer
                        }
                        impressum {
                            text
                        }
                    }
                    translation(language: DE) {
                        title
                        content
                        slug
                        featuredImage {
                            node {
                              sourceUrl
                              altText
                            }
                        }
                        seo {
                            title
                            canonical
                            metaDesc
                        }
                        acf {
                            subtitle
                            showPartners
                            showTeamMembers
                            showTestimonials
                            slider {
                                title
                                subtitle
                                images {
                                    image {
                                        sourceUrl
                                    }
                                }
                            }
                            headerText {
                                text
                                button
                            }
                            faqs {
                                question
                                answer
                            }
                            impressum {
                                text
                            }
                        }
                    }
                }
            }
        `,
        {
            variables: {
                id: slug,
                idType: 'URI'
            }
        }
    )
    return data
}

export async function getTeamMembers() {
    const data = await fetchAPI(
        `
            query MembersQuery {
                teamMembers(last: 99) {
                    nodes {
                        id
                        title
                        acf {
                            languages
                            position
                            positionDe
                        }
                        featuredImage {
                            node {
                                sourceUrl(size: LARGE)
                            }
                        }
                    }
                }
            }                
        `
    )
    return data
}

export async function getTestimonials() {
    const data = await fetchAPI(
        `
            query TestimonialsQuery {
                testimonials(last: 99) {
                    nodes {
                        title
                        id
                        acf {
                            testimonialText
                            testimonialTextDe
                            boat
                            boatDe
                        }
                    }
                }
            }                
        `
    )
    return data
}

export async function getPartners() {
    const data = await fetchAPI(
        `
            query PartnersQuery {
                partners(last: 99) {
                    nodes {
                        id
                        acf {
                            link
                        }
                        featuredImage {
                            node {
                                altText
                                sourceUrl
                            }
                        }
                    }
                }
            }                
        `
    )
    return data
}

export async function getMatterportBoats() {
    const data = await fetchAPI(
        `
            query MatterportBoats {
                matterportBoats {
                    nodes {
                        acf {
                            boatId
                            matterportId
                        }
                    }
                }
            }                
        `
    )
    return data
}

export async function getBuyFields() {
    const data = await fetchAPI(
        `
            query BuyPageFields {
                buys {
                    nodes {
                        acf {
                            seoOptimisedTextDe
                            seoOptimisedTextEn
                            welcheYachtBoxDe {
                                text
                                title
                            }
                            welcheYachtBoxEn {
                                text
                                title   
                            }
                        }
                    }
                }
            }               
        `
    )
    return data
}

export async function getManageFields() {
    const data = await fetchAPI(
        `
        query ManagePageFields {
            manages {
              nodes {
                afc {
                  endTextBlock {
                    buttonLabelDe
                    buttonLabelEn
                    textDe
                    textEn
                  }
                  introTextBox {
                    buttonLabelDe
                    buttonLabelEn
                    textDe
                    textEn
                    titleDe
                    titleEn
                  }
                  sellImageBlock {
                    buttonLabelDe
                    buttonLabelEn
                    textDe
                    textEn
                    titleDe
                    titleEn
                  }
                  yachtBau {
                    auslieferungDe
                    auslieferungEn
                    forschungDe
                    forschungEn
                    schliffsbauDe
                    schliffsbauEn
                    textBoxLeftDe
                    textBoxLeftEn
                    textBoxRightDe
                    textBoxRightEn
                    wartungDe
                    wartungEn
                    werftAuswahlDe
                    werftAuswahlEn
                  }
                  yachtBesatzungDe
                  yachtBesatzungEn
                  yachtBetrieb {
                    administrationDe
                    administrationEn
                    beverageDe
                    beverageEn
                    crewManagementDe
                    crewManagementEn
                    dockingDe
                    dockingEn
                    insuranceDe
                    insuranceEn
                    introDe
                    introEn
                    itineraryDe
                    itineraryEn
                    provisioningDe
                    provisioningEn
                    shippingAgentDe
                    shippingAgentEn
                    sparePartsDe
                    sparePartsEn
                    transportDe
                    transportEn
                  }
                  yachtServiceDe
                  yachtServiceEn
                  yachtVersicherungDe
                  yachtVersicherungEn
                }
              }
            }
          }               
        `
    )
    return data
}

export async function getSellFields() {
    const data = await fetchAPI(
        `
            query SellPageFields {
                sells {
                    nodes {
                        acf {
                        askUsImageBlock {
                            textDe
                            textEn
                            titleDe
                            titleEn
                        }
                        contact {
                            textDe
                            textEn
                            titleDe
                            titleEn
                        }
                        expertiseTextBox {
                            buttonLabelDe
                            buttonLabelEn
                            fieldGroupName
                            textDe
                            textEn
                            titleDe
                            titleEn
                        }
                        introTextDe
                        introTextEn
                        meetTheTeamDe
                        meetTheTeamEn
                        strategyTextDe
                        strategyTextEn
                        htmlBoxDe
                        htmlBoxEn
                        }
                    }
                }
            }     
        `
    )
    return data
}
